<template>
  <div class="filters">

    <div
        v-if="page !== 'blocks' && page !== 'bankings' && page !== 'distributors' && page !== 'agencies' && page !== 'users'">
      <Divider type="dashed">
        <b>Categorías</b>
      </Divider>
      <Dropdown v-model="selectedGame" :options="games" optionLabel="name" optionValue="code"
                placeholder="Lotería" class="categorias"
                v-if="page !== 'report-sales' && page !== 'report-conalot'"></Dropdown>

      <Dropdown v-model="selectedGameReport" :options="allgames" optionLabel="name" optionValue="code"
                placeholder="Lotería" class="categorias"
                v-if="page === 'report-sales' || page === 'report-conalot'"
      ></Dropdown>

      <div>
        <div>
          <div class="opciones" v-if="page!=='cancel'">
            <Divider type="solid">
              <b>Opciones</b>
            </Divider>
            <!-- Fecha -->
            <Calendar v-model="selectedDate"
                      dateFormat="dd/mm/yy"
                      :showIcon="true"
                      :maxDate="maxDate"
                      :showOtherMonths="true"
                      :selectOtherMonths="true"
                      v-if="page !== 'cancel' && page !== 'report-sales' &&
                      page !== 'report-conalot'"/>
            <!-- Periodo-->
            <Calendar v-model="selectedDateRange"
                      dateFormat="dd/mm/yy"
                      :showIcon="true"
                      :maxDate="maxDate"
                      :selectOtherMonths="true"
                      :showOtherMonths="true"
                      selectionMode="range"
                      v-if="page === 'report-sales' || page === 'report-conalot'"/>

            <div v-if="page !== 'report-sales' && page !== 'report-conalot' ">
              <!-- Sorteo -->
              <Dropdown v-model="selectedSorteo" :options="sorteos" optionLabel="tproductoxsorteo"
                        placeholder="Sorteo" class="filterItem" :loading="loadingSorteo" :showClear="true"
                        :onClick="refreshSorteo"
                        :filter="true"/>

              <div v-if="selectedGame !== 'animal'">
                <!-- Lista -->
                <div v-if="page === 'lists-online'">
                  <Dropdown v-model="selectedLista" :options="listas" optionLabel="tlista" optionValue="id_lista"
                            placeholder="Lista" class="filterItem" :loading="loadingLista"/>
                </div>
                <div v-else>
                  <Dropdown v-model="selectedLista" :options="listas" optionLabel="tlista" optionValue="id_lista"
                            placeholder="Lista" class="filterItem" :loading="loadingLista"
                            :showClear="true"/>
                </div>


                <!-- Tipo -->
                <Dropdown v-model="selectedTipo" :options="tipos" optionLabel="ttipo_lista" optionValue="id_tipo_lista"
                          placeholder="Tipo" class="filterItem" :loading="loadingTipo"
                          :onClick="refreshTipo"
                          :showClear="true"/>

                <h1 v-if="page === 'lists-online'">
                  <!-- Ordenar por -->
                  <Dropdown v-model="selectedOrderBy" :options="orderBy" optionLabel="name" optionValue="code"
                            placeholder="Ordenar por" class="filterItem" :showClear="true"/>
                </h1>
              </div>
            </div>
            <div v-else>
              <h1 v-if="page === 'lists-online'">
                <!-- Ordenar por Animalitos -->
                <Dropdown v-model="selectedOrderByAnimalitos" :options="orderByAnimalitos" optionLabel="name"
                          placeholder="Ordenar por" class="filterItem" :showClear="true"/>
              </h1>
            </div>
            <!--    ###########################################################################################################-->
          </div>
          <div v-if="page === 'report-sales' || page === 'report-conalot'">
            <!-- Producto -->
            <Dropdown v-model="selectedProducto" :options="productos" optionLabel="tproducto"
                      placeholder="Producto" class="filterItem" :loading="loadingProducto"
                      :onClick="refreshProducto"
                      scroll-height="300px"
                      :showClear="true"/>
          </div>

          <div v-if="page === 'report-sales'">

            <div v-if="selectedGroupBy === 'modalidad'">
              <!-- Modalidad -->
              <Dropdown v-model="selectedModalidad" :options="modalidades" optionLabel="tmodalidad_pago"
                        placeholder="Perfil de Pago" class="filterItem" :loading="loadingModalidad"
                        :onClick="refreshModalidad"
                        :showClear="true"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="page !== 'report-conalot'">
      <Accordion>
        <AccordionTab header="Filtros">
          <!-- Comercializador -->
          <Dropdown v-model="selectedComercializador" :options="comercializadores" optionLabel="tcomercializador"
                    placeholder="Bloque" class="filterItem" :loading="loadingComercializador"
                    :onClick="refreshComercializador"
                    :showClear="true"
                    v-if="Number(roleId)<2"
                    :filter="true"/>
          <!-- Banca -->
          <Dropdown v-model="selectedBanca" :options="bancas" optionLabel="tbanca"
                    placeholder="Bancas" class="filterItem" :loading="loadingBanca"
                    :showClear="true"
                    :onClick="refreshBanca"
                    v-if="Number(roleId) < 3 && page !== 'blocks'"
                    :filter="true"/>
          <!-- Distribuidor -->
          <Dropdown v-model="selectedDistribuidor" :options="distribuidores" optionLabel="tdistribuidor"
                    placeholder="Distribuidor" class="filterItem" :loading="loadingDistribuidor"
                    :onClick="refreshDistribuidor"
                    :showClear="true"
                    v-if="Number(roleId) < 4 && page !== 'bankings' && page !== 'blocks'"
                    :filter="true"/>
          <!-- Agencia -->
          <Dropdown v-model="selectedAgencia" :options="agencias" optionLabel="tagencia"
                    placeholder="Punto de venta" class="filterItem" :loading="loadingAgencia" :showClear="true"
                    :onClick="refreshAgencia"
                    v-if="Number(roleId) < 5 && page !== 'bankings' && page !== 'distributors' && page !== 'blocks'"
                    :filter="true"
                    :key="componentKey"/>
        </AccordionTab>
      </Accordion>


    </div>
    <!--Filtros opcionales-->

    <!--    Agrupar por -->
    <div v-if="page === 'report-sales' || page === 'report-conalot'">
      <Divider align="left" type="dashed">
        <b>Agrupar por</b>
      </Divider>
      <Dropdown v-model="selectedGroupBy" :options="groupBy"
                optionLabel="label"
                optionValue="code"
                optionGroupLabel="label" optionGroupChildren="items"
                placeholder="Agrupar por" class="filterItem"
                :onClick="refreshAgencia"
                scroll-height="400px"
      />
    </div>

    <!--    &lt;!&ndash;    Estatus de ticket&ndash;&gt;-->
    <!--    <div v-if="page === 'sold'">-->
    <!--      <Divider align="left" type="dashed">-->
    <!--        Estatus de Tickets-->
    <!--      </Divider>-->
    <!--      <Dropdown v-model="selectedTransactionStatus" :options="transactionStatus" optionLabel="name" optionValue="code"-->
    <!--                placeholder="Estaus de ticket" class="filterItem"-->
    <!--                :showClear="true"-->
    <!--                :filter="true"/>-->
    <!--    </div>-->

    <!-- Mostrar borrados -->
    <div v-if="page === 'agencies' || page === 'distributors' || page === 'bankings' || page === 'blocks'"
         style="padding: 30px 0 20px 0;">
      <div class="displayDeleted">
        <input type="checkbox" v-model="displayDeleted">Mostrar borrados
      </div>

      <div v-if="roleId === 1">
        <div class="displayDeleted"
             v-if="page === 'agencies' ||
           page === 'distributors' ||
           page === 'bankings' ||
           page === 'blocks'">
          <input type="checkbox" v-model="displayExternal">Mostrar externos
        </div>
      </div>

    </div>

    <Divider/>
    <div class="action-buttons">
      <Button type="button" icon="pi pi-filter-slash" label="" class="p-button"
              @click="clearFilters()"/>
      <Button class="search-button" label="Buscar" icon="pi pi-search" iconPos="right" :onClick="getData"/>
    </div>

  </div>
</template>

<script>
import MainService from "@/service/backend/MainService";
import Divider from "primevue/divider";
import generalMixin from "@/mixins/generalMixin";
import moment from 'moment-timezone';
import refreshMixin from "@/mixins/refreshMixin";

export default {
  name: "Filter",
  components: {
    'Divider': Divider
  },
  mixins: [generalMixin, refreshMixin],
  created() {
    this.mainService = new MainService();
    this.roleId = this.$store.getters.getProfile.role_id;
    this.entityId = this.$store.getters.getProfile.entity_id;

    this.today = this.getTodayDate();
    this.maxDate = moment().locale('es').toDate()
    this.$primevue.config.locale.firstDayOfWeek = 1;
  },
  data() {
    return {
      loading1: false,
      loadingSorteo: false,
      loadingLista: false,
      loadingTipo: false,
      loadingSorteoAnimalitos: false,
      loadingProducto: false,
      loadingModalidad: false,

      loadingComercializador: false,
      loadingBanca: false,
      loadingDistribuidor: false,
      loadingAgencia: false,

      comercializadores: [],
      distribuidores: [],
      bancas: [],
      agencias: [],
      sorteos: [],
      productos: [],
      modalidades: [],
      sorteosAnimalitos: [],
      listas: [
        {
          "id_lista": 1,
          "tlista": "TRIPLES",

        },
        {
          "id_lista": 0,
          "tlista": "TERMINALES",
        },
        {
          "id_lista": 3,
          "tlista": "TRIPLES CON SIGNO",
        },
        {
          "id_lista": 2,
          "tlista": "TERMINALES CON SIGNO",
        },
      ],
      tipos: [],
      // Options
      selectedDate: moment().locale('es').toDate(),
      selectedDateRange: [
        moment().locale('es').toDate(),
        moment().locale('es').toDate()
      ],
      selectedSorteo: null,
      selectedLista: null,
      selectedTipo: null,
      selectedOrderBy: null,
      selectedAnimalitosSorteo: null,
      selectedOrderByAnimalitos: null,
      // Filters
      selectedDistribuidor: null,
      selectedComercializador: null,
      selectedBanca: null,
      selectedAgencia: null,
      // Group
      selectedGroupBy: 'producto',

      selectedGame: 'lottery',
      selectedGameReport: 'all',
      // Reports
      selectedProducto: false,
      selectedModalidad: false,
      games: [
        {name: 'Lotería', code: 'lottery'},
        {name: 'Animalitos', code: 'animal',}
      ],
      allgames: [
        {name: 'Todos', code: 'all'},
        {name: 'Lotería', code: 'lottery'},
        {name: 'Animalitos', code: 'animal',}
      ],
      orderBy: [{name: 'Número', code: '1'}, {name: 'Monto', code: '2 desc'}],
      orderByAnimalitos: [{name: 'Animalito', code: 'id_elemento_lista'}, {name: 'Monto', code: 'venta desc'}],
      componentKey: null,
      displayZeroes: false,
      roleId: null,
      entityStatus: [
        {name: 'ACTIVO', code: '1'},
        {name: 'BORRADO', code: '4'},
        {name: 'BLOQUE TOTAL', code: '3'},
        {name: 'BLOQUE PARCIAL', code: '2'},
        {name: 'INACTIVO', code: '0'},
      ],
      displayDeleted: false,
      displayExternal: false,
      transactionStatus: [
        {name: 'VENDIDO', code: '0'},
        {name: 'ANULADO', code: '1'},
        {name: 'GANADOR', code: '2'},
        {name: 'REVERSADO FUERA DE TIEMPO', code: '3'},
        {name: 'PAGADO', code: '4'}
      ],
      selectedTransactionStatus: null,
      maxDate: null,
      minDate: null,
      groupBy: [],
    }
  },
  props: {
    page: String,
    conalot: Boolean,
  },
  updated() {
    this.selectedLista = (this.page === 'report-sales' || this.page === 'report-conalot') ? null : this.selectedLista;
  },
  mounted() {
    this.clearFilters();
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
    this.selectedLista = this.page === 'lists-online' ? 1 : null;
    this.groupBy = this.getGroupByParams(this.getIdentity())
  },
  methods: {
    clearFilters() {
      this.comercializadores = null;
      this.distribuidores = null;
      this.bancas = null;
      this.agencias = null;

      this.sorteos = null;
      this.sorteosAnimalitos = null;
      // this.listas = null;
      this.tipos = null;
      this.productos = null;
      this.modalidades = null;

      this.selectedSorteo = null;
      this.selectedLista = null;
      this.selectedTipo = null;
      this.selectedComercializador = null;
      this.selectedBanca = null;
      this.selectedDistribuidor = null;
      this.selectedAgencia = null;

      this.selectedGroupBy = 'producto';

      this.selectedProducto = null;
      this.selectedModalidad = null;

      this.displayDeleted = false;
      this.selectedTransactionStatus = null;
    },

    getData() {
      this.selectedDateRange[1] = this.selectedDateRange[1] === null ? this.selectedDateRange[0] : this.selectedDateRange[1];
      this.$emit('get-data', {
        // Category
        category: this.selectedGame,
        reportCategory: this.selectedGameReport,
        // Options
        page: this.page,
        fecha: this.convertDate(this.selectedDate),
        fechas: [
          this.selectedDateRange[0]?this.convertDate(this.selectedDateRange[0]):null,
          this.selectedDateRange[1]?this.convertDate(this.selectedDateRange[1]):null,
        ],
        sorteo: this.selectedSorteo && this.selectedSorteo,
        lista: this.selectedLista && this.selectedLista,
        tipo_lista: this.selectedTipo && this.selectedTipo,
        orderBy: this.selectedOrderBy && this.selectedOrderBy,
        // Filters
        comercializador: this.selectedComercializador && this.selectedComercializador,
        banca: this.selectedBanca && this.selectedBanca,
        distribuidor: this.selectedDistribuidor && this.selectedDistribuidor,
        agencia: this.selectedAgencia && this.selectedAgencia,
        // Animalitos
        game: this.selectedGame,
        selectedGame: this.selectedGame,
        sorteoAnimalitos: this.selectedAnimalitosSorteo && this.selectedAnimalitosSorteo,
        orderByAnimalitos: this.selectedOrderByAnimalitos && this.selectedOrderByAnimalitos,
        // Reports
        selectedGroupBy: this.selectedGroupBy && this.selectedGroupBy,
        displayZeroes: this.displayZeroes,
        // Reports
        producto: this.selectedProducto,
        modalidad: this.selectedGroupBy === 'modalidad' ? this.selectedModalidad : null,
        // Display Deleted
        displayDeleted: this.displayDeleted,
        displayExternal: this.displayExternal,
        cstatus_transaccion: this.selectedTransactionStatus,
        identity: this.getIdentity(),
      });
    },
    async refreshSorteo() {
      this.loadingSorteo = true;
      this.selectedSorteo = null;
      this.sorteos = await this.mainService.getSorteos({
        fecha: this.formatDate2(this.selectedDate),
        game: this.selectedGame
      });
      this.loadingSorteo = false;
    },
    async refreshLista() {
      this.loadingLista = true;
      this.listas = await this.mainService.getListas({
        fecha: this.formatDate2(this.selectedDate),
        game: this.selectedGame
      });
      this.loadingLista = false;
    },
    async refreshTipo() {
      this.loadingTipo = true;
      this.selectedTipo = null;
      this.tipos = await this.mainService.getTipos({
        fecha: this.formatDate2(this.selectedDate),
        game: this.selectedGame
      });
      this.loadingTipo = false;
    },
    async refreshComercializador() {
      this.loadingComercializador = true;
      this.selectedComercializador = null;
      const date = this.page === 'report-sales' ? this.selectedDateRange[0] : this.selectedDate;
      this.comercializadores = await this.mainService.getComercializador({
        fecha: this.formatDate2(date),
        fechas: [this.formatDate2(this.selectedDateRange[0]), this.formatDate2(this.selectedDateRange[1])],
        identity: this.getIdentity(),
        comercializador: this.selectedComercializador,
        conalot: this.conalot,
        page: this.page,
        hide_external: true,
      });
      this.loadingComercializador = false;
    },
    async refreshBanca() {
      this.loadingBanca = true;
      this.selectedBanca = null;
      const date = this.page === 'report-sales' ? this.selectedDateRange[0] : this.selectedDate;
      this.bancas = await this.mainService.getBanca({
        fecha: this.formatDate2(date),
        identity: this.getIdentity(),
        comercializador: this.selectedComercializador,
        conalot: this.conalot,
        page: this.page,
        hide_external: true,
      });
      this.loadingBanca = false;
    },
    async refreshDistribuidor() {
      this.loadingDistribuidor = true;
      this.selectedDistribuidor = null;
      const date = this.page === 'report-sales' ? this.selectedDateRange[0] : this.selectedDate;
      this.distribuidores = await this.mainService.getDistribuidor({
        fecha: this.formatDate2(date),
        identity: this.getIdentity(),
        comercializador: this.selectedComercializador,
        banca: this.selectedBanca,
        conalot: this.conalot,
        page: this.page,
        hide_external: true,
      });
      this.loadingDistribuidor = false;
    },
    async refreshAgencia() {
      this.loadingAgencia = true;
      this.selectedAgencia = null;
      const date = this.page === 'report-sales' ? this.selectedDateRange[0] : this.selectedDate;
      this.agencias = await this.mainService.getAgencia({
        fecha: this.formatDate2(date),
        identity: this.getIdentity(),
        comercializador: this.selectedComercializador,
        banca: this.selectedBanca,
        distribuidor: this.selectedDistribuidor,
        conalot: this.conalot,
        page: this.page,
        hide_external: true,
      });
      this.loadingAgencia = false;
    },
    async refreshProducto() {
      this.loadingProducto = true;
      this.selectedProducto = null;
      this.productos = await this.mainService.getProducto({selectedGameReport: this.selectedGameReport});
      this.loadingProducto = false;
    },
    async refreshModalidad() {
      this.loadingModalidad = true;
      this.selectedModalidad = null;
      this.modalidades = await this.mainService.getModalidad({selectedGameReport: this.selectedGameReport});
      this.loadingModalidad = false;
    },

  }
}
</script>

<style scoped lang="scss">
.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 2px 0 5px 0;
}

::v-deep(.categorias) {
  border-color: var(--green-banklot);
  border-width: initial;
  background-color: var(--green-banklot);

  .p-dropdown-label, .p-dropdown-trigger, .p-inputtext {
    color: var(--surface-0);
    font-weight: bold;
  }
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}

.search-button {
  width: 75%;
  margin-left: 5px;
}

.categorias .p-dropdown-trigger {
  color: white;
}

.p-dropdown .p-component .p-inputwrapper .p-inputwrapper-filled .categorias .p-dropdown-trigger {
  color: white;
}

.displayDeleted {
  color: var(--surface-600)
}
</style>
