import MainService from "@/service/backend/MainService";

export default {
    created() {
        this.mainService = new MainService;
    },
    data() {
        return {

        }
    },
    methods: {
        async refreshComercializador() {
            return await this.mainService.getComercializador({
                fecha: this.theDate,
                identity: this.identity,
                id_comercializador: this.selectedComercializador,
                page: 'agencies',
            });
        },

        async refreshBanca() {
            this.loading = true;
            this.selectedBanca = null;
            this.bancas = await this.mainService.getBanca({
                fecha: this.theDate,
                identity: this.identity,
                id_comercializador: this.selectedComercializador,
                page: 'agencies',
            });
            this.loading = false;
        },

        getGroupByParams(identity) {
            let entityArr =  [];
            if (identity.roleId < 2) {
                entityArr.push({label: 'BLOQUE', code: 'comercializador'})
            }
            if (identity.roleId < 3) {
                entityArr.push({label: 'BANCA', code: 'banca'})
            }
            if (identity.roleId < 4) {
                entityArr.push({label: 'DISTRIBUIDOR', code: 'distribuidor'})
            }
            if (identity.roleId < 5) {
                entityArr.push({label: 'PUNTO DE VENTA', code: 'agencia'})
            }
            return [
                {
                    label: 'Categoría',
                    items: [
                        {label: 'FECHA', code: 'fecha'},
                        {label: 'PRODUCTO', code: 'producto'},
                        {label: 'SORTEO', code: 'sorteo'},
                        // {label: 'PERFIL DE PAGO', code: 'perfil_pago_premios'},
                    ]
                },
                {
                    label: 'Entidad',
                    items: entityArr
                }
            ];
        },

    },

}